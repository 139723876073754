<template>
  <div class="head-container head-form-container basePicListPage-header" :class="{ pb30: designedStyle }">
    <BaseForm customClass="customClass" ref="form" label-width="100px" :cols="formField" :form="query" @change="to">
      <template #nameSlot="{ scoped: { prop, placeholder } }">
        <el-input v-model="query[prop]" :placeholder="placeholder" @keyup.enter.native="to"></el-input>
      </template>
      <template #categorySlot="{ scoped: { prop, placeholder } }">
        <ImageTypeTreeSelect
          size="mini"
          style="width: 100%"
          :placeholder="placeholder"
          :selectedValue.sync="query[prop]"
          :maxHeight="150"
          @change="to"
        />
      </template>
      <template #protoTypeCategorySlot="{ scoped: { prop, placeholder } }">
        <ProtoTypeTreeSelect
          size="mini"
          style="width: 100%"
          :placeholder="placeholder"
          :selectedValue.sync="query[prop]"
          :maxHeight="150"
          @change="to"
        />
      </template>
      <template #sexSlot="{ scoped: { prop, placeholder } }">
        <selectList :placeholder="placeholder" :options="SEX_LIST" :keyword.sync="query[prop]" @change="to" />
      </template>
      <template #managerSlot="{ scoped: { prop, placeholder } }">
        <selectList
          filterable
          :placeholder="placeholder"
          :keyObj="keyObj"
          :menus="accountList"
          :keyword.sync="query[prop]"
          @change="to"
        />
      </template>

      <template #disableSlot="{ scoped: { prop, placeholder } }">
        <selectList :placeholder="placeholder" :options="PIC_DISABLE_LIST" :keyword.sync="query[prop]" @change="to" />
      </template>

      <template #shelvesSlot="{ scoped: { prop, placeholder } }">
        <selectList :placeholder="placeholder" :options="PIC_SHELVES_LIST" :keyword.sync="query[prop]" @change="to" />
      </template>

      <template #seasonSlot="{ scoped: { prop, placeholder } }">
        <selectList :placeholder="placeholder" :options="SEASONS_LSIT" :keyword.sync="query[prop]" @change="to" />
      </template>
      <template #multiplySearchSlot="{ scoped: { prop } }">
        <multiSearch :query="query" @handleSearch="handleSearch" />
      </template>
      <template #timeSlot="{ scoped: { prop } }">
        <SaleDateTime :time.sync="time" @change="to" />
      </template>
      <template #designedTimeSlot="{ scoped: { prop } }">
        <SaleDateTime :time.sync="time" @change="to" />
      </template>
      <template #levelSlot="{ scoped: { prop, placeholder } }">
        <selectList :placeholder="placeholder" :options="LEVEL_LSIT" :keyword.sync="query[prop]" @change="to" />
      </template>
      <template #size_psdSlot="{ scoped: { prop, placeholder } }">
        <selectList
          :placeholder="placeholder"
          :options="[
            {
              id: 0,
              name: '未上传psd'
            },
            { id: 1, name: '已上传psd' }
          ]"
          :keyword.sync="query[prop]"
          @change="to"
        />
      </template>
      <template #tortSlot>
        <el-select v-model="query.isTort" placeholder="请选择" @change="toQuery" clearable>
          <el-option v-for="{ value, label } in tortList" :value="value" :label="label" :key="value"></el-option>
        </el-select>
      </template>
      <template #doneSlot>
        <el-button
          :class="{ mt50ml20: designedStyle }"
          size="mini"
          type="primary"
          icon="el-icon-search"
          @click="to"
        ></el-button>
      </template>
    </BaseForm>
  </div>
</template>

<script>
import ImageTypeTreeSelect from '@/components/imageTypeTreeSelect'
import ProtoTypeTreeSelect from '@/components/protoTypeTreeSelect'
import SaleDateTime from '@/components/saleDateTime'

import { commonEheaderMixin } from '@/mixins'
import multiSearch from './multiSearch'
import { mapGetters } from 'vuex'
import { getList } from '@/api/statistics.js'
import { objBeArray } from '@/utils'
import { SEX_LIST, LEVEL_LSIT, SEASONS_LSIT, PIC_DISABLE_LIST, PIC_SHELVES_LIST } from '@/utils/constant'
import { getSubAccount } from '@/api/subAccount'

export default {
  props: {
    formField: {
      type: Array,
      required: true
    },
    //用于调节定制产品管理模块的样式
    designedStyle: {
      type: Boolean,
      default: false
    }
  },
  mixins: [commonEheaderMixin],

  components: {
    ImageTypeTreeSelect,
    ProtoTypeTreeSelect,
    SaleDateTime,
    multiSearch
  },
  data() {
    return {
      SEX_LIST: objBeArray(SEX_LIST),
      LEVEL_LSIT: objBeArray(LEVEL_LSIT),
      SEASONS_LSIT: objBeArray(SEASONS_LSIT),
      PIC_DISABLE_LIST: objBeArray(PIC_DISABLE_LIST),
      PIC_SHELVES_LIST: objBeArray(PIC_SHELVES_LIST),
      time: [],
      keyObj: {
        label: 'createByName',
        value: 'createByName'
      },
      accountList: [],
      tortList: [
        { label: '全部', value: 'all' },
        { label: '是', value: 1 },
        { label: '否', value: 0 }
      ]
    }
  },
  computed: {
    ...mapGetters(['isMain', 'name'])
  },
  methods: {
    to() {
      this.$nextTick(() => {
        this.toQuery()
      })
    },
    //子组件传递过来的方法，用于多角度搜索
    handleSearch() {
      this.to()
    }
  },
  created() {
    if (this.isMain) {
      const page = { pageIndex: 1, pageSize: 10 }
      getList(page).then((res) => {
        console.log('我被调用', res.detail)
        this.accountList = res.detail
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pb30 {
  padding-bottom: 30px !important;
  .mt50ml20 {
    margin: 50px 0 0 20px;
  }
}
</style>
