<template>
  <div class="flex-warpper">
    <div class="left-container">搜索：</div>
    <div :class="{ ml27: basicStyle }" class="right-container">
      <span
        :class="{ label: true, active: index === currentIndex }"
        v-for="(item, index) in searchList"
        :key="index"
        @click="handleClick(index, item.prop)"
      >
        {{ item.name }}
      </span>
      <el-input
        clearable
        @clear="handleClear"
        @keyup.enter.native="handleSearch"
        :placeholder="`请输入${searchList[currentIndex].name}`"
        v-model="query[searchList[currentIndex].prop]"
      ></el-input>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    basicStyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentIndex: 0,
      searchList: [
        { name: '款式', prop: 'styleName' },
        { name: '内部SKU', prop: 'sku' },
        { name: '自定义SKU', prop: 'customSku' }
      ]
    }
  },
  methods: {
    handleClick(index, item) {
      this.currentIndex = index
      const queryList = ['styleName', 'sku', 'customSku']
      queryList.map((query) => {
        if (item !== query) {
          this.$set(this.query, `${query}`, undefined)
        }
      })
    },
    handleSearch() {
      this.$emit('handleSearch')
    },
    handleClear() {
      this.$set(this.query, this.searchList[this.currentIndex].prop, undefined)
      this.$emit('handleSearch')
    }
  }
}
</script>

<style lang="scss" scoped>
.ml27 {
  margin-left: 27px;
}
.flex-warpper {
  display: flex;
  align-items: center;
}
.left-container {
  color: #606266;
  font-weight: bold;
  cursor: default;
  margin: 50px 20px 0 0;
  width: 120px;
  text-align: right;
}
.right-container {
  margin-top: 20px;
}
.label {
  font-size: 14px;
  color: #606266;
  font-weight: bold;
  cursor: pointer;
  margin-right: 20px;
}
.active {
  color: blue;
}
</style>
